<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus"/>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'SignInfoIndex',
  components: {
    PageHeaderLayout
  },
  data() {
    return {
      menus: [
        // {
        //   icon: 'el-icon-notebook-2',
        //   display_name: '季度绩效列表',
        //   url: '/ecp/list',
        //   permission_name: 'kol-analysis-list'
        // },
        {
          icon: 'mubiao',
          display_name: '绩效目标一览',
          url: '/ecp/performance',
          permission_name: 'ecp_team'
        },
        {
          icon: 'fenpei',
          display_name: '奖金分配',
          url: '/ecp/bonus',
          permission_name: 'ecp_bonus'
        },
        {
          icon: 'fenpei',
          display_name: '红人关联',
          url: '/ecp/kol_mapper',
          permission_name: 'ecp_kol_mapper'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
